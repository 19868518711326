<template>
  <div class="row">
    <!-- messages left -->
      <div class="col-md-4 col-xl-4">
      <div class="card">
        <div class="card-block">
          <h6 class="mb-4">{{ $t('messagesLeft') }}</h6>
          <div class="row d-flex align-items-center">
            <div class="col-12">
              <div class="d-flex justify-content-between w-100">
                <h3 class="f-w-300 d-flex align-items-center m-b-0">{{ license.available_requests }}</h3>
                <router-link to="purchase">{{$t('makePurchase')}}</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="card">
        <div class="card-block">
          <h6 class="mb-4">{{ $t('lookupRequestsLeft') }}</h6>
          <div class="row d-flex align-items-center">
            <div class="col-12">
              <div class="d-flex justify-content-between w-100">
                 <h3 class="f-w-300 d-flex align-items-center m-b-0">{{ license.available_lookup_requests }}</h3>
                <router-link to="purchase">{{$t('makePurchase')}}</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="card">
        <div class="card-block">
          <h6 class="mb-4">{{ $t('authRequestsLeft') }}</h6>
          <div class="row d-flex align-items-center">
            <div class="col-12">
              <div class="d-flex justify-content-between w-100">
                 <h3 class="f-w-300 d-flex align-items-center m-b-0">{{ license.available_auth_requests }}</h3>
                <router-link to="purchase">{{$t('makePurchase')}}</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LicenseStatistic',
  props: {
    license: {
      type: Object,
    },
  },
}
</script>
